.intro-container{
    position: relative; height:85vh;
}
.main-img{
    width:100vw;
    height:85vh;
    object-fit: cover;
    filter: brightness(60%)
}
.header{
    color: white;
    margin-top: -300px;
    z-index: 1000;
    position: relative;
    margin-left: 20px
    
}
.about-container{
    position: relative;
    margin: 15px;
}
h2{
    color: #003973;
}
span{
    line-height: 1.5;
    display:inline-block;
    max-width: 700px;
}
h3{
    color: white;
   margin-top: 25px;
}
.solutions-container{
    background-color: rgb(0,57,115,0.6);
    
   
}
.solutions{
    margin:15px;
    padding-top: 15px;
    padding-bottom: 15px;
}
/* contact */
.contactTitle{
    color:white;
    font-size: 20PX;
    padding-bottom: 20px;

}
.questions{
    color:white;
}
.start-text{
	margin:auto;
	margin-top: 50px;
	margin-left:35vw;
}
.email-container{
    border-radius: 5px;
	margin:auto;
	max-width: 50vw;
	background-color:#003973;
	padding: 20px
}
textarea{
	height:150px;
	width: 80%;
}
.phoneInput{
	max-width: 200px
}
.submitButton{
	background-color: white;
	color:#003973;
    font-weight: bolder;
    padding: 10px 5px 10px 5px;
    border-radius: 5px;
    margin-top: 10px;
}
.login-button{
    color:white;
    font-size: 13px;
    margin: 5px;
    background-color: #003973 ;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}
.organic-container{
    padding-top:30px;
    padding-bottom:30px;
    text-align: center;
}
.steps{
    font-size: 30px;
}
.step-and-description{
   margin:auto;
    z-index: 1000;
    color:white;
}
.step-image{
    object-fit:cover;
    width:100vw;
    height: 300px;
    /* background: linear-gradient(rgba(250,250,250, 0.2), rgba(250,250,250, 0.2)); */
    z-index:-1;
}
.how-header{
    font-size: 28px;
    font-weight: bold;
    color:#003973
}
.imgstep1{
    
    background-image: url('https://res.cloudinary.com/kcb-software-design/image/upload/v1682469199/GLS/handshaking-in-office-2021-08-26-15-29-32-utc_oxpcgy.jpg');
}
.imgstep2{
    background-image: url('https://res.cloudinary.com/kcb-software-design/image/upload/v1682469199/GLS/blue-sky-and-cloud-2022-12-15-22-39-29-utc_e1z7d6.jpg');
}
.imgstep3{
    background-image: url('https://res.cloudinary.com/kcb-software-design/image/upload/v1682469199/GLS/searching-browsing-concept-hand-click-search-page-2022-11-04-04-44-49-utc_h1b3kw.jpg');
}
.imgstep4{
    background-image: url('https://res.cloudinary.com/kcb-software-design/image/upload/v1682469369/GLS/fnger-pointing-on-globe-2022-04-08-10-20-09-utc_njcgwv.jpg');
}
.imgstep5{
    background-image: url('https://res.cloudinary.com/kcb-software-design/image/upload/v1682469200/GLS/close-up-of-hand-using-on-white-mouse-and-clicking-2023-01-27-02-08-31-utc_ednong.jpg');
}
.imgstep6{
    background-image: url('https://res.cloudinary.com/kcb-software-design/image/upload/v1682469199/GLS/businessman-in-black-suit-lift-up-the-stair-caree-2022-09-07-15-55-08-utc_p0guh7.jpg');
}
.imagestep5::before{
 opacity: 0.7;
z-index: -1;
}
.step-container{
 
    width:100vw;
    height: 250px;
    background-size: cover;
    background-position: center;
    display:flex;
}
@media  (max-width:650px) {
    .email-container{
        width:90vw;
        max-width: max-content;
    }
}